/*
  message-box 设计
  * alert - 支持自动消失
  * confirm - 确认类 - 确定下一步流程
  * prompt - no
*/
.message {
  position: absolute;
  position: fixed;
  z-index: 3010;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  height: 0 !important;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    vertical-align: middle;
  }

  a {
    color: inherit;
    text-decoration: none;
    padding: 0 10px;
    margin-right: -1px;
    border-right: 1px solid #eee;
  }

  &-description {
    @extend %inline-block;
    padding: 4px 10px;

    h2 {
      margin-bottom: 10px;
    }
  }

  $padding: 15px;

  &-inner {
    vertical-align: top;
    position: relative;
    max-width: 746px;
    @extend %inline-block;
    color: #fff;
    font-size: 14px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 0 0 4px 4px;
    -webkit-user-select: none;
    overflow: hidden;
  }

  &-action {
    overflow: hidden;
    padding: $padding 0;
    white-space: nowrap;
    border-left: 1px solid #474747;
    @extend %inline-block;
    vertical-align: top;

    &-help {
      text-align: right;
    }
  }

  &-content {
    text-align: left;
    padding: $padding;
    line-height: 1.5;
  }

  &-display {
    &-tip {

      .message-no,
      .message-yes {
        @extend %none;
      }
    }

    &-alert {
      .message-no {
        @extend %none;
      }
    }

    &-tip,
    &-alert,
    &-confirm {
      transform: translateY(0);
    }
  }

  &-reverse {
    .message-no {
      float: right;
    }
  }

  pre {
    white-space: pre-wrap;
  }

  &__more {
    color: #cc73cf;
    cursor: pointer;
  }

  &__other {
    display: none;
  }
}

.memberDiv {
  background: #ebebeb;
  width: 150px;
  float: left;
  line-height: 3;
  font-size: 14px;
  color: #505050;
  box-sizing: border-box;
  cursor: pointer;
}

.memberDivActive {
  background: #cc73cf;
  color: #fff;
}

.memberDiv2 {
  width: 150px;
  float: left;
  line-height: 5;
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
  cursor: pointer;
}

.memberDivActive2 {
  color: #cc73cf;
}

.memberCount-common-certain {
  font: inherit;
  font-size: 12px;
  line-height: 28px;
  vertical-align: middle;
  padding: 0 20px;
  border: 1px solid #878787;
  border-radius: 4px;
  color: #666;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#efefef));
  cursor: pointer;
}

.select-input {
  input {
    background-image: url(../images/Down.png);
    background-position: calc(100% - 10px) center;
    background-size: 14px;
    background-repeat: no-repeat;
  }

  ul {
    z-index: 10;
    display: none;
    position: absolute;
    left: 0;
    border: 1px solid #ebebeb;
    border-top: none;
    box-shadow: 0 0 5px #bbb;
    max-height: 200px;
    overflow: auto;

    //&::-webkit-scrollbar{
    //  width: 0;
    //}
    li {
      width: 18em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 1px solid #ebebeb;
      background-color: #fff;
      color: #333;
      line-height: 28px;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: #fff;
        background-color: #cc73cf;
      }

      &.active {
        color: #fff;
        background-color: #cc73cf;
      }
    }
  }
}

.memberCount-select-span {
  padding-left: 25px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px;
  background-image: url(../images/noselect.jpg);
  margin-right: 20px;
  cursor: pointer;
}

.memberCount-select-spanActive {
  background-image: url(../images/selected.jpg);
}