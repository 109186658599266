/*
  # 权限设定 - 界面分三种
  * 使用 CSS 来控制 - 各种导航入口
  * 主要使用 JavaScript 状态来控制
    - 操作结果(权限相关)由服务器端控制
    - 列表(又称一级页面)/二级页面(依赖一级页面状态)
  * 其他细节

  .role-shop
  .role-system-1
*/

%hidden {
  display: none;
}

// 店员系统
.role-shop {
  $list: ( // 店铺
      '.shop-navi-list .mini-navi-button',
      '.shop-navi-info .navi-side',
      '.op-nav-list .navi-side',
      '.op-nav-count .navi-side',
      // 会员
      '.member-navi-list .member-button-jurisdiction',
      '.memberex-navi-list .member-button-jurisdiction',
      // '#member-navi-fixed',
      // 优惠券
      '.coupon-navi-list .mini-navi-button',
      // 工作流程页面
      '#page-task .navi-side',
      // 运营模块
      '.yunying-items-btn',
      '#btn-spu-add',
      '#btn-spu-category',
      '#btn-spu-attr'
    );

  @each $selector in $list {
    #{$selector} {
      @extend %hidden;
    }
  }
}

// 管理系统 - 店长级角色
.role-system-0 {
  #page-shop .shop-navi-list .mini-navi-button {
    display: inline-block !important;
  }

  .role0-hiddens {
    display: inline-block !important;
  }
}

.role0-hiddens {
  display: none !important;
}

#page-shop .shop-navi-list .mini-navi-button {
  display: none !important;
}