#refund {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -100px;
  margin-left: -160px;
  border: 1px solid #ddd;
  width: 320px;
  background-color: #fff;
  padding: 0 15px;
  display: none;
}

.refund {
  &-head {
    line-height: 36px;
  }

  &-body-item {
    margin: 5px 0;
  }

  &-foot {
    margin: 5px 0 10px;
    text-align: right;
    line-height: 24px;

    a {
      margin-left: 10px;
    }
  }
}

.confirm {
  &-out {
    display: none;
  }

  &-out-box {
    font-size: 13px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &-inner {
    border-radius: 4px;
    background-color: #fff;
    padding: 20px;
    width: 250px;
    text-align: center;
  }

  &-title {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 15px;
  }

  &-bottom {
    display: flex;
    justify-content: flex-end;
  }

  &-button {
    margin-top: 20px;
    border-radius: 4px;
    margin-left: 15px;
    padding: 5px 10px;
    background-color: #cc73cf;
    color: #fff;
    cursor: pointer;
  }

  &-button-quit {
    margin-top: 20px;
    border-radius: 4px;
    margin-left: 15px;
    padding: 5px 10px;
    background-color: #eee;
    color: #fff;
    cursor: pointer;
  }
}