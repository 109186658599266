.login-body {
  background: url("../images/login/bg.png") no-repeat center/cover;
  height: 100vh;
  min-height: 625px;
  min-width: 1200px;
  .header {
    padding: 22px 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 220px;
      // height: 70px;
      .logo-img {
        width: 100%;
        height: 100%;
      }
    }
    .phone {
      font-size: 14px;
      color: #fff;
    }
  }
}
.container {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  .body {
    display: flex;
    justify-content: flex-end;
    .form {
      margin-right: 120px;
      position: relative;
      margin-top: 132px;
      padding: 52px 28px 32px;
      width: 388px;
      height: 344px;
      box-sizing: border-box;
      background-color: #fff;
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.03);
      border-radius: 8px;
      flex-direction: column;
      justify-content: space-around;
      .del {
        position: absolute;
        top: 30px;
        right: 17px;
        cursor: pointer;
        width: 16px;
        height: 16px;
        .del-icon {
          width: 100%;
          height: 100%;
        }
      }

      .admin-title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.65);
      }
      .title {
        font-size: 20px;
        color: #1890ff;
        font-weight: bold;
      }

      .row {
        margin-top: 20px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #e1e1e1;
        padding: 8px 12px;
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        .value {
          border: none;
          outline: none;
          height: 24px;
          &::placeholder {
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }

      .row-code {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .row-label {
          display: flex;
          align-items: center;
          border-radius: 4px;
          border: 1px solid #e1e1e1;
          padding: 8px 12px;
          .icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
          .value {
            border: none;
            outline: none;
            height: 24px;
            &::placeholder {
              color: rgba(0, 0, 0, 0.25);
            }
          }
          .type-icon {
            width: 14px;
            height: 14px;
          }
        }
        .code-btn {
          flex-shrink: 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          outline: none;
          padding: 8px 16px;
          line-height: 26px;
          border: 1px solid #e1e1e1;
          border-radius: 4px;
          background-color: #fff;
          cursor: pointer;
          &:disabled {
            background-color: #ccc;
            cursor: no-drop;
          }
        }
      }

      .error-line {
        margin-top: 10px;
        color: red;
        font-size: 12px;
      }
      .submit {
        margin-top: 12px;
        padding: 8px 0;
        width: 100%;
        border: none;
        outline: none;
        border-radius: 4px;
        background: #1890ff;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
      }
      .option {
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;
        .option-item {
          cursor: pointer;
          font-size: 14px;
          color: #1890ff;
        }
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        content: "";
        width: 348px;
        height: 12px;
        border-radius: 0 0 8px 8px;
        background-color: #dde3ed;
      }
    }
  }
}
