.buma {
  .user-form_list {
    left: 196px;
    top: 44px;

    li {
      background: #fff;
    }
  }

  #user-default-view-content {
    display: none !important;
  }

  background: url(../images/new_order_back.png) no-repeat 50% 50%;
  background-size: cover;

  .sidebar {
    width: 160px;

    li:first-of-type {
      a {
        background: rgba(255, 255, 255, 0.2);
      }
    }

    li {
      background: rgba(0, 0, 0, 0.2);

      a {
        background: transparent;
        color: #fff !important;

        .arrow {
          display: none !important;
        }
      }

      a:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      a:after {
        background: #e43b3b !important;
      }
    }
  }

  #logo {
    width: 160px;
  }

  #order-detail-content {
    width: 21%;
    float: right;
    border-left: 14px solid transparent;
  }

  #order-coupon-content {
    padding-top: 21px;
    width: 47.5%;
    float: right;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.9);
    color: #000;
  }

  #order-default-view-content {
    height: 70vh !important;
    background: rgba(255, 255, 255, 0.9) url(../images/order-code-empty.png) no-repeat 50% 50%;
    margin-bottom: 18px;
    border-radius: 4px;
  }

  .user-default-view-content {
    background: rgba(255, 255, 255, 0.9) url(../images/order-member-empty.png) no-repeat 50% 50%
  }

  .order-user-brief {
    background: rgba(255, 255, 255, 0);
    padding: 0 !important;

    dd {
      color: #000;
    }

    dl {
      margin: 0 !important;
    }
  }

  #user-detail-view-content {
    width: 47.5%;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
  }

  #code,
  #code-ic,
  #coupon-code {
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    color: white;
    padding-left: 10px;
  }

  #code::-webkit-input-placeholder,
  #code-ic::-webkit-input-placeholder,
  #coupon-code::-webkit-input-placeholder {
    color: #fff;
  }

  input[type="sumbit"] {
    background: rgba(255, 255, 255, 0.8) !important;
    color: #333 !important;
  }

  #order-pay-methods {
    background: rgba(255, 255, 255, 0.95);
    border-top: 1px solid #f25056;
    width: 100%;
    left: 0;
  }

  #order-pay-methods button {
    background: #e43b3d;
    color: #fff;
  }

  #order-pay-methods button:hover {
    background: #b30a00;
    color: #fff;
  }

  .header-contact dt,
  .header-contact dd {
    color: #fff;
  }

  .header-user h1 {
    color: #fff;
  }

  .mini-navi-return {
    color: #fff;
    background: url(../images/rebackmiuse.png) no-repeat 10px 50%;
  }

  .mini-navi-return:hover {
    background: url(../images/rebackuse.png) no-repeat 10px 50%;
  }

  .mini-navi-titles li.current {
    color: #e70004;
  }

  .ctrl-sidebar {
    display: inherit;
  }

  #order-remark-view-content {
    textarea {
      color: #000;
      border: 1px solid #999;
      background: rgba(255, 255, 255, 0);
    }

    textarea::-webkit-input-placeholder {
      color: #999;
    }
  }

  .order-navi-form {
    border-bottom: 1px solid #fff;
  }

  #header {
    border-bottom: 1px solid #b30a00;
    display: none;
  }

  .order-detail-item-operator {
    select {
      option {
        color: #333;
      }
    }
  }

  #air-up {
    background: rgba(220, 211, 211, 0.5);

    p {
      color: #000;
    }

    .p {
      color: #b40901 !important;
    }
  }

  #order-detail-view-content {
    max-height: 70vh;
    border-radius: 4px;
  }



  .order-chang-price {
    width: 55px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    height: 20px;
    padding-left: 5px;
  }

  .order-chang-num {
    width: 20px;
    border: 1px solid #999;
    height: 20px;
    text-align: center;
  }

  .sidebar-side {
    margin-left: -160px;
  }

  #order-detail-view-content {
    height: 70vh;
    overflow-y: auto;
  }

  // sku-detail
  #order-detail-view-content {

    // 容器
    .order-detail-item {
      border: 0;
    }

    // 主要信息
    .order-detail-item-main {
      overflow: hidden;
      position: relative;
      min-height: 80px;
      padding-left: 110px;
    }
  }



  .cut-number-order {
    border: 1px solid #999;
    background: #999;
    color: #fff;
  }

  .cut-number-order:hover {
    border: 1px solid #454545;
    background: #454545;
  }

  .add-number-order:hover {
    border: 1px solid #454545;
    background: #454545;
  }

  .add-number-order {
    border: 1px solid #999;
    background: #999;
    color: #fff;
  }

  #order-operator-content-buma {
    display: none !important;
  }

  #order-form-content-section {
    padding-bottom: 0;
  }

  .order-detail-item-tag {
    color: #ff7800;
    background: transparent;
    border: 1px solid transparent;
    padding: 0;
    height: 30px;
    line-height: 28px;
    margin: 0 3px;
  }

  //商品容器
  .order-good-box:hover {
    border: 1px solid #fd7900;
    border-bottom: none;
    cursor: pointer;
  }

  .lingshou .order-good-box {
    border: 1px solid #e70004;
    border-bottom: none;
    margin-right: 1vw;
    float: left;
    width: 11vw;
    height: 12.5vw;
    position: relative;
    margin-bottom: 14px;
    overflow: hidden;
    box-sizing: border-box;

    .order-good-box-right {
      display: none;
      background: transparent url(../images/numberTan.png) no-repeat 50% 50%;
      ;
      position: absolute;
      right: 0;
      top: 0;
      width: 30px;
      height: 30px;
      line-height: 18px;
      color: #fff;

      p {
        text-align: right;
      }
    }

    .order-good-box-img {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .order-good-box-img-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #e43b3d;
      color: #fff;
      height: 35px;
      padding: 3px 0;

      p {
        margin-left: 5px;
      }
    }

    .order-good-box-img-zz {
      padding: 3px 0;
      position: absolute;
      bottom: 41px;
      background: rgba(0, 0, 0, 0.6);
      width: 100%;
      color: #fff;
      height: 36px;

      p {
        margin-left: 5px;
        height: 18px;
        line-height: 18px;
        font-size: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .fuwu .order-good-box {
    overflow: hidden;
    border: 1px solid #e70004;
    border-bottom: none;
    margin-right: 1vw;
    float: left;
    width: 14vw;
    height: 9.5vw;
    position: relative;
    margin-bottom: 14px;
    overflow: hidden;
    box-sizing: border-box;

    .order-good-box-right {
      display: none;
      background: transparent url(../images/numberTan.png) no-repeat 50% 50%;
      ;
      position: absolute;
      right: 0;
      top: 0;
      width: 30px;
      height: 30px;
      line-height: 18px;
      color: #fff;

      p {
        text-align: right;
      }
    }

    .order-good-box-img {
      width: 100%;

      img {
        width: 40%;
        margin: 5%;
      }

      div {
        position: absolute;
        top: 12%;
        left: 45%;
        margin-left: 16px;
      }
    }

    .order-good-box-img-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #e43b3d;
      color: #fff;
      height: 35px;
      padding: 3px 0;

      p {
        margin-left: 5px;

        span {
          margin-left: 10px;
        }
      }
    }

    .order-good-box-img-zz {
      position: absolute;
      bottom: 35px;
      width: 100%;
      height: 30px;

      p {
        margin-left: 5px;
        line-height: 30px;
        font-size: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .number-action-delete {
    background: url(../images/upload--reset.png) no-repeat left center;
    width: 28px;
    height: 28px;
    background-size: contain;
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
  }

  .rights_url {
    padding-left: 20px;
    line-height: 30px;
    background: url(../images/rights_url.png) no-repeat 0 50%;
    background-size: 20px;
  }

  .end_rights {
    line-height: 30px;

    img {
      position: relative;
      top: 5px;
    }
  }
}