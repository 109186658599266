%loading {
  height: 104px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(../images/requesting.gif);
}

.search-nothing {
  @extend %loading;
  height: 200px;
  background-image: url(../images/search-nothing.png);
}

%empty-content {
  height: 500px;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

$statusMap: (shop: (list: list,
    shop-info: info,
    shop-staff: staff,
    shop-configure: configure,
    shop-add: form,
    async: async,
    site: site,
    async-good: async-good,
    shop-edit: form,
    employee-add: employee,
    employee-edit: employee,
    shop-review-list:review,
    review-detail:reviewDetail,
  ),
  member: (ic: ic,
    card: card,
    list: list,
    form: form,
    charge: charge,
    fixed: fixed,
    rule-list: rule-list,
    rule-form: rule-form,
    count: count),
  memberex: (list: list,
    basic: basic,
    basic-detail: basic-detail,
    body: body,
    body-detail: body-detail,
    facial: facial,
    facial-detail: facial-detail,
    charge: charge,
    rule-list: rule-list,
    rule-form: rule-form),
  coupon: (list: list,
    page: page,
    detail: detail),
  memberCount: (common-list: common-list,
    sales-list: sales-list,
    focus-list: focus-list),
  couponCount: (get-list: get-list,
    cancel-list: cancel-list),
  order: (list: list,
    apply: apply,
    form: form,
    detail: detail,
    count: count),
  memberTag: (list-one:list-one,
    list-two:list-two),
  memberProfile: (list: list,
    detail: detail),
  inventory: (list: list,
    form: form,
    detail: detail,
    2-list: 2-list,
    2-detail: 2-detail),
  goods: (sku-list: sku-list,
    sku-detail: sku-detail,
    sku-add: sku-add,
    sku-edit: sku-edit,
    spu-list: spu-list,
    spu-detail: spu-detail,
    spu-form: spu-form,
    spu-category: spu-category,
    spu-attr: spu-attr,
    spu-attr-form: spu-attr-form,
    refund-list: refund-list,
    refund-detail: refund-detail,
    sales-list:sales-list,
    spu-form1: spu-form1),
  task: (today: today,
    list: list),
  yunying: (list-msg: list-msg,
    list-tel: list-tel,
    list-wx: list-wx,
    form-msg: form-msg,
    form-tel: form-tel,
    form-wx: form-wx,
    count: count),
  keliu: (list: list,
    chart: chart),
  evaluate:(good-list:good-list,
    serve-list:serve-list),
  appointment:(appoint-shop:appoint-shop,
    appoint-home:appoint-home,
    appoint-main:appoint-main,
    main-list:main-list,
    appoint-list:appoint-list,
    appoint-form:appoint-form,
    appoint-class:appoint-class,
    class-form:class-form,
    class-more:class-more,
    appoint-site:appoint-site,
    site-form:site-form,
    appoint-detail:appoint-detail),
  reply:(list:list,
    form:form,
    forms:forms,
    detail:detail,
    details:details),
  demo:(list-one:list-one,
    list-two:list-two,
    form-one:form-one,
    form-two:form-two,
    detail-one:detail-one,
    detail-two:detail-two,
  ),
  package:(list:list,
    form:form,
    edit:edit,
    up-form:up-form,
    up-list:up-list,
    detail:detail),
  newrule:(list:list,
    detail:detail,
    chongzhi:chongzhi,
    peizhi:peizhi));

@each $id,
$list in $statusMap {
  #page-#{$id} {
    .empty-content {
      @extend %empty-content;
      background-image: url(../images/empty-#{$id}.png);
    }

    // 已经全局了...
    .loading {
      @extend %loading;
    }
  }

  @each $status,
  $classname in $list {
    @at-root {

      .#{$id}-navi-#{$classname},
      ##{$id}-#{$classname} {
        @extend %none;

        .#{$id}-status-#{$status} & {
          @extend %block;
          overflow: hidden;
        }
      }

      .#{$id}-status-#{$status} .#{$id}-navi-#{$status}-title {
        color: #cc73cf;
      }
    }
  }
}

// 会员身材信息/基本信息
@each $status in size-list,
size-form,
basic,
basic-detail {
  .member-form-#{$status} {
    @extend %none;

    .member-form-status-#{$status} & {
      @extend %block;
    }
  }
}

// coupone
@each $status in a,
x,
y,
z {
  #form-coupon-#{$status}-content {
    @extend %none;

    .coupon-form-status-#{$status} & {
      @extend %block;
    }
  }
}