* {
  -webkit-tap-highlight-color: transparent;
}

body {
  min-width: 1000px;
  line-height: 1.5;
  font-size: 14px;
  font-family: '微软雅黑';
}

textarea {
  resize: none;
}

.common-empty {
  height: 200px;
  width: 100%;
  background: #f8f8f8 url(../images/empty-guanjia-tongyong.png) no-repeat 50% 50%;
}

.li-card {
  background: white;
  padding-left: 10px;
  margin-bottom: 1px;
}

.del-card {
  float: right;
  margin-top: 5px;
  width: 22px;
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: #505050;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 999em #fff;
}

%block {
  display: block;
}

%none {
  display: none;
}

%inline-block {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: '';
  font-size: 0;
  height: 0;
  clear: both;
  overflow: hidden;
  display: block;
}

.label {
  display: block;
  margin-top: 35px;
  height: 44px;
  border: 1px solid #a3a3a3;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 14px;
  overflow: hidden;

  input {
    width: 100%;
    height: 100%;
    border: 0;
    outline: none;
    font: inherit;
  }
}

.label:hover,
.label:active {
  border-color: #cc73cf;
}

.button {
  font: inherit;
  font-size: 12px;
  line-height: 27px;
  padding: 0 20px;
  border-radius: 4px;
  border: 1px solid #878787;
  color: #666;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#efefef));
  cursor: pointer;
}

.hidden-nav {
  height: 0;
  margin: 0 !important;
  border: none !important;
}

.input-miss {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}

.button:hover {
  background: #fff;
}

.button:focus {
  outline: none;
}

.skin-btn {
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #cc73cf;
  cursor: pointer;
}

.skin-btn-disabled {
  background-color: darkgray;
}

%icon {
  @extend %inline-block;
  width: 18px;
  height: 18px;
}

@each $key in edit,
delete {
  .icon-#{$key} {
    @extend %icon;
    cursor: pointer;
    background-image: url(../images/icon-#{$key}.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover,
    &:active {
      background-image: url(../images/icon-#{$key}-hover.png);
    }
  }
}

.icon-delete {
  margin-left: 10px;
}

.icon-add {
  @extend %inline-block;
  line-height: 24px;
  height: 24px;
  padding-left: 28px;
  vertical-align: middle;
  background: url(../images/add.png) no-repeat 0 50%;
}

.skin {

  // 提示类样式
  &-helper {
    color: #878787;
  }

  &-button {
    background-color: #cc73cf;

    &:hover {
      background-color: #86339a;
    }
  }
}

.textarea {
  width: 298px;
  border: 1px solid #e0e0e0
}

.form-warning {
  color: red;
}

.fuceng {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
  display: none;
  top: 0;
  left: 0;
}

#form-coupon-y-content {
  .upload-wrap {
    width: 290px;
    height: 160px;
  }
}

#form-coupon-z-content,
#form-coupon-a-content,
#form-coupon-x-content {
  .upload-wrap {
    width: 290px;
    height: 160px;
  }

  .width-155 {
    width: 155px;
  }

  .coupon-x-p {
    margin-left: 20px;
    width: 120px;
    text-align: left;
  }

  .pay-outer {
    margin-left: 20px;
    color: #cc73cf;
    background: white;
    border: 1px solid #cc73cf;
    border-radius: 3px;
    height: 28px;
    width: 80px;
  }

  .pay-fuceng {
    width: 400px;
    height: 285px;
    border-radius: 3px;
    background: white;
    position: absolute;
    left: 50%;
    margin-left: -143px;
    top: 100px;
    z-index: 11;
    display: none;

    .fuceng-p {
      font-size: 16px;
      margin: 30px 0 0 38px;
    }

    .control-value {
      width: 222px;
    }

    .mt-80 {
      margin-top: 80px;
    }

    .pay-outer-next {
      margin-left: 20px;
      background: #e0e0e0;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      font-size: 14px;
      height: 28px;
      padding: 0 15px
    }

    .div-img {
      position: absolute;
      left: 130px;

      .p-one {
        margin-left: 28px;
      }

      .p-two {
        color: #999;
      }
    }

    .button-quit {
      margin-top: 190px;
    }

    .shop-pay-img {
      width: 140px;
      height: 140px;
    }

    .pay-outer-quit,
    .pay-outer-quit-next {
      cursor: pointer;
      margin-left: 170px;
      background: white;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      font-size: 14px;
      height: 28px;
      padding: 0 15px
    }
  }

  #coupon-event-number {
    width: 155px;
  }

  .wei-kj {
    display: none;
  }

  .lineH30 {
    line-height: 30px
  }

  .div-navi-side-store {
    display: inline-block;
  }
}

#page-member {
  .revenue-fuceng {
    width: 400px;
    border-radius: 3px;
    background: white;
    position: absolute;
    left: 50%;
    margin-left: -310px;
    top: 50px;
    z-index: 11;
    display: none;

    .button-quit {
      margin-top: 190px;
      margin-bottom: 10px;
    }

    .pay-outer-quit,
    .revenue-outer-quit,
    .pay-quit {
      cursor: pointer;
      margin-left: 170px;
      background: white;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      font-size: 14px;
      height: 28px;
      padding: 0 15px
    }

    .fuceng-p {
      font-size: 16px;
      margin: 15px 0 0 23px;
    }

    .shop-pay-img {
      width: 140px;
      height: 140px;
    }

    .div-img {
      position: absolute;
      left: 130px;
      font-size: 12px;
    }

    .width-275 {
      width: 275px;
    }

    .button-next {
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .pay-outer-next,
    .revenue-outer-next {
      margin-left: 20px;
      background: #e0e0e0;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      font-size: 14px;
      height: 28px;
      padding: 0 15px
    }
  }
}

th ul li {
  cursor: pointer;
}

.displaynone {
  display: none !important;
}

.editTurn-role {
  pointer-events: initial !important;
  color: #cc73cf;
  cursor: pointer;
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat
}

.text-p {
  color: #999;
  line-height: 20px;
  font-size: 12px;
}

.editTurn-role:hover {
  padding: 0 20px;
  background-image: url(../images/newDdit.png)
}